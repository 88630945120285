<template>
  <section ref="homme" class="home" :style="{ backgroundColor: themeConfig.backgroundColor }"
    :class="`home detect_deviece_${deviceType} state_${deviceType}`">
    <div v-show="showDebug" class="ad-msg">
      <p>themeConfig --> {{ themeConfig }} </p>
      <hr>
      <p>domainConfig --> {{ domainConfig }} </p>
      <hr>
      <p>host --> {{ host }}</p>
      <hr>
      <p>adsensConfig --> {{ adsense }}</p>
    </div>

    <van-list v-model="loading" :finished="finished" :finished-text="bomTitle" :offset="20"
      class="main-feed" loading-text="Loading..." :immediate-check="false" @load="onLoad">
      <div class="newlist_row">
        <template v-for="(item, index) in adjustedItems">
          <component :is="item.componentType" v-if="item.adtype === 'component'"
            :key="`key_${index}`" :item="item.data"
            :class="[`item-theme-${item.data.type === 1 ? 'lead' : 'collection'}`, invalidClass(item.data.type === 1 ? 'lead' : 'collection')]"
            @customClick="goLanding(item.data.id)" />
          <div v-else-if="item.adtype === 'ad'" :key="`key-ad_${index}`" class="adsItem ad-row">
            <div :id="`pos_${item.position}`" />
            <div v-if="showDebug" class="ad-msg">
              position={{ `pos_${item.position}` }} <br>
              adconfig={{ getAdConfig(item.position) }}
            </div>
          </div>
        </template>
      </div>
    </van-list>
  </section>
</template>

<script>
import commonMethods from '@/mixin/commonMethods.js';
import adjustedItemsMixin from '@/mixin/adjustedItemsMixin.js';

export default {
  name: 'HomeMain',
  mixins: [commonMethods, adjustedItemsMixin],
  beforeDestroy () {
    console.log('-------------------beforeDestroy');
    window.adsTag.stopAds(document.querySelector('#pos_4'));
    window.adsTag.stopAds(document.querySelector('#pos_7'));
  },
  async activated () {
    console.log('Component activated-----------');
    try {
      this.loadBeedsadScript();  // 重新加载广告脚本
    } catch (error) {
      console.error('An error occurred in getList:', error);
    }
  },
  deactivated () {
    console.log('Component deactivated-----------');
  },
  async mounted () {
    console.log('-------------------mounted')
    this.initData()
  },
  methods: {
    getAdConfig (position) {
      // 假设 `position` 与 `adsense` 数组的索引对应
      return this.adsense[`bees_pos_${position}`] || {};
    },
    async initData () {
      console.log('loadList');
      try {
        await this.getList();
        try {
          console.log('loadList func running');
          this.loadBeedsadScript();  // 重新加载广告脚本
        } catch (error) {
          console.error('An error occurred in getList:', error);
        }
      } catch (error) {
        console.error('An error occurred in getList:', error);
        // 可以在这里添加错误处理逻辑
      }
    },
    loadBeedsadScript () {
      window.adsTag.cmd.push(() => {
        window.adsTag.renderAds(document.querySelector('#pos_4'), 336, 280, this.adsense.bees_pos_4);
        window.adsTag.renderAds(document.querySelector('#pos_7'), 336, 280, this.adsense.bees_pos_7);
      });
    },

    goLanding (id) {
      console.log(id);
      this.$eventrackFb('news_goto_detail', 'click', {
        id
      });
      this.$router.push({ name: 'blanding', query: { id, thme: this.themeConfig.landType } });
    },

  },
}
</script>
